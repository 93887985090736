<template>
  <b-card
      class="logisticscarriager-edit-wrapper"
  >
    <!-- form -->
    <b-form id="logisticscarriagerForm" class="edit-form mt-2">

      <!--基本信息-->
      <b-col md="12">
        <b-card header="基本信息">
          <b-row>
            <!--承运人-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="承运人"
                  label-for="carriage_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="carriage_name"
                    taggable
                    push-tags
                    v-model="logisticscarriager.carriage_name"
                    :options=carriager_options
                    class="select-size-sm"
                    placeholder="请选择承运人"
                    :disabled="disable_flag"
                />
              </b-form-group>
            </b-col>
            <!--车辆类型-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车辆类型"
                  label-for="car_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="car_type"
                    :options="getCodeOptions('warehouse_feed_cartype')"
                    v-model="car_type"
                    class="select-size-sm"
                    placeholder="请选择车辆类型"
                    :disabled="disable_flag"
                />
              </b-form-group>
            </b-col>
            <!--车牌号-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车牌号"
                  label-for="car_no"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_no"
                    size="sm"
                    v-model="logisticscarriager.car_no"
                    :disabled="disable_flag"
                />
              </b-form-group>
            </b-col>
            <!--车型-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车型"
                  label-for="car_cycle"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_cycle"
                    size="sm"
                    v-model="logisticscarriager.car_cycle"
                    :disabled="disable_flag"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--装载信息-->
      <b-col md="12">
        <b-card header="装载信息">
          <b-row>
            <!--核定载重(T)-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="核定载重(T)"
                  label-for="check_weight"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="check_weight"
                    size="sm"
                    type="number"
                    v-model="logisticscarriager.check_weight"
                />
              </b-form-group>
            </b-col>
            <!--装载长度(M)-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="装载长度(M)"
                  label-for="load_length"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="load_length"
                    size="sm"
                    type="number"
                    v-model="logisticscarriager.load_length"
                />
              </b-form-group>
            </b-col>
            <!--装载宽度(M)-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="装载宽度(M)"
                  label-for="load_width"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="load_width"
                    size="sm"
                    type="number"
                    v-model="logisticscarriager.load_width"
                />
              </b-form-group>
            </b-col>
            <!--装载高度(M)-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="装载高度(M)"
                  label-for="load_height"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="load_height"
                    size="sm"
                    type="number"
                    v-model="logisticscarriager.load_height"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--其他信息-->
      <b-col md="12">
        <b-card header="其他信息">
          <b-row>
            <!--司机姓名-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机姓名"
                  label-for="car_driver"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_driver"
                    size="sm"
                    v-model="logisticscarriager.car_driver"
                />
              </b-form-group>
            </b-col>
            <!--司机电话-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机电话"
                  label-for="car_tel"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_tel"
                    size="sm"
                    v-model="logisticscarriager.car_tel"
                />
              </b-form-group>
            </b-col>
            <!--副司机姓名-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="副司机姓名"
                  label-for="car_driver_second"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="car_driver_second"
                    size="sm"
                    v-model="logisticscarriager.car_driver_second"
                />
              </b-form-group>
            </b-col>
            <!--副司机电话-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="副司机电话"
                  label-for="car_tel_second"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="car_tel_second"
                    size="sm"
                    v-model="logisticscarriager.car_tel_second"
                />
              </b-form-group>
            </b-col>
            <!--车主姓名-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车主姓名"
                  label-for="car_owner"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="car_owner"
                    size="sm"
                    v-model="logisticscarriager.car_owner"
                />
              </b-form-group>
            </b-col>
            <!--车主电话-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车主电话"
                  label-for="car_owner_tel"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="car_owner_tel"
                    size="sm"
                    v-model="logisticscarriager.car_owner_tel"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--备注-->
          <b-row>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-textarea
                    id="remark"
                    size="sm"
                    v-model="logisticscarriager.remark"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--保存按钮-->
      <b-col cols="12" class="mt-50">
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
        >
          保存
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">
          取消
        </b-button>
      </b-col>

    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import logisticscarriagerStore from './logisticscarriagerStore'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty
} from '@core/utils/filter'
import axios from '@axios'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
  },
  data() {
    return {
      id: ref(0),
      logisticscarriager: ref({}),
      carriager_options: [],
      car_type:{},
      disable_flag:false,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('logisticscarriager')) store.registerModule('logisticscarriager', logisticscarriagerStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('logisticscarriager')) store.unregisterModule('logisticscarriager')
    })

    const edit = function () {
      store.dispatch('logisticscarriager/edit', {id: this.id}).then(res => {
        this.logisticscarriager = res.data.data
        if(!this.logisticscarriager.new){
          this.disable_flag = true
          this.car_type = getCode('warehouse_feed_cartype', this.logisticscarriager.car_type)
          this.logisticscarriager.carriage_name = {label: this.logisticscarriager.carriage_name}
        }
      })
    }

    const view = function () {
      store.dispatch('logisticscarriager/view', {id: this.id}).then(res => {
        this.logisticscarriager = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      //校验
      let flag = true
      if (isEmpty(this.logisticscarriager.carriage_name)) {
        toast.error('请选择 承运人')
        flag = false
      }
      if (isEmpty(this.car_type)) {
        toast.error('请选择 车辆类型')
        flag = false
      }
      if (isEmpty(this.logisticscarriager.car_no)) {
        toast.error('请填写 车牌号')
        flag = false
      }
      if (isEmpty(this.logisticscarriager.car_cycle)) {
        toast.error('请填写 车型')
        flag = false
      }
      if (isEmpty(this.logisticscarriager.car_driver)) {
        toast.error('请填写 司机姓名')
        flag = false
      }
      if (isEmpty(this.logisticscarriager.car_tel)) {
        toast.error('请填写 司机电话')
        flag = false
      }
      if (this.logisticscarriager.check_weight < 0) {
        toast.error('核定载重 不能为负')
        flag = false
      }
      if (this.logisticscarriager.load_length < 0) {
        toast.error('装载长度 不能为负')
        flag = false
      }
      if (this.logisticscarriager.load_width < 0) {
        toast.error('装载宽度 不能为负')
        flag = false
      }
      if (this.logisticscarriager.load_height < 0) {
        toast.error('装载高度 不能为负')
        flag = false
      }
      if (!flag) return

      this.$swal({
        title: '确定数据录入无误吗？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '已确认',
        cancelButtonText: '再看看',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          //转换
          if(!isEmpty(this.logisticscarriager.carriage_name.label)){
            this.logisticscarriager.carriage_name = this.logisticscarriager.carriage_name.label
          }
          this.logisticscarriager.car_type = this.car_type.value

          store.dispatch('logisticscarriager/save', this.logisticscarriager).then(res => {
            if(res.data.code ==  0){
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-logisticscarriager-list'});
            }else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const initOptions = function () {
      let that = this
      axios.post('/api/logisticsuser/list').then(function (res) {
        let list = res.data.data.list
        list.forEach(item => {
          if (item.user_type === 2) {
            that.carriager_options.push({label: item.user_name})
          }
        })
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      initOptions,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      addNumber,
      isEmpty,
      Number,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
    this.initOptions()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
